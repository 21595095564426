body {
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
li {
  list-style-type: none;
}
img {
  width: 100%;
}
.is-relative {
  position: relative;
}
.is-flex{
  display: flex;
}
.container {
  max-width: 100%;
  padding: 0px;
}
.container .row {
  margin-left: 0;
  margin-right: 0;
}
.container .row > *{
  padding: 0;
}
.global-padding {
  padding-left: 7vw;
  padding-right: 7vw;
}
/* lenis */
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/* end lenis */
/*
|------------------------------------------------------
|  Typograph
|------------------------------------------------------
*/
@font-face {
  font-family: ActayWide-Bold;
  src: url(fonts/ActayWide-Bold.otf);
}
@font-face {
  font-family: Actay-Regular;
  src: url(fonts/Actay-Regular.otf);
}
@font-face {
  font-family: Actay-RegularItalic;
  src: url(fonts/Actay-RegularItalic.otf);
}
@font-face {
  font-family: Marcellus-Regular;
  src: url(fonts/Marcellus-Regular.ttf);
}

h1 {
  font-family: Actay-Regular;
  font-size: 44px;
  line-height: 58px;
  letter-spacing: -1px;
  color: #1F1916;
}

h2 {
  font-family: Actay-Regular;
  font-size: 44px;
  line-height: 55px;
  letter-spacing: -1px;
  color: #1F1916;
}

h3 {
  font-family: ActayWide-Bold;
  font-size: 16px;
  line-height: 20px;
  color: #1F1916;
  opacity: 0.5;
  text-transform: uppercase;
  margin-bottom: 16px;
}

p {
  font-family: Actay-Regular;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: #1F1916;
}

.title {
  font-family: Marcellus-Regular;
  font-size: 100px;
  line-height: 133px;
  color: #FFFFFF;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); 
  z-index: 1;
}

.ventina-btn {
  font-family: ActayWide-Bold;
  font-size: 18px;
  line-height: 23px;
  color: #1F1916;
  display: inline-block;
  text-transform: uppercase;
  margin-top: 70px;
  padding-bottom: 12px;
  border-bottom: 1px solid #D1CDC3;
}
.ventina-btn img{
  width: 19.2px;
  height: 14.96px;
  margin-left: 15.8px;
}

.scroll {
  margin: 0;
  position: absolute;
  font-family: ActayWide-Bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  bottom: 30px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0%); 
  cursor: pointer;
}
/*
|----------------------------------------------------------------
| Query media
|----------------------------------------------------------------
*/
@media (max-width: 1700px) {
  .title {
    font-size: clamp(64px,5.5vw,100px);
    line-height: clamp(80px,7vw,133px);
  }
  h1 {
    font-size: clamp(30px,3vw,44px);
    line-height: clamp(36px,4vw,58px);
  }
  h2 {
    font-size: clamp(30px,3vw,44px);
    line-height: clamp(36px,3.2vw,55px);
  }
  h3 {
    margin-bottom: clamp(14px,0.7vw,16px);
  }
  .ventina-btn {
    font-size: clamp(16px,0.8vw,18px);
    line-height: clamp(20px,1vw,23px);
    margin-top: clamp(30px,2vw,70px);
  }
}
@media (max-width: 1400px) {
  .global-padding {
    padding-left: 3.5vw;
    padding-right: 3.5vw;
  }
}
@media (max-width: 1200px) {
  p {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 36px;
  }
  h2 {
    font-size: 30px;
    line-height: 36px;
  }
  .title {
    font-size: 64px;
    line-height: 80px;
    text-align: center;
  }
  .global-padding {
    padding-left: 9vw;
    padding-right: 9vw;
  }
  
  h3 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 14px;
  }
  .ventina-btn {
    margin-top: 50px;
    padding-bottom: 9px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 420px) {
  .title {
    font-size: 58px;
    line-height: 72px;
  }

}
